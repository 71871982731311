import { computed, inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { IResponseMap } from '@core/domain/interfaces';
import {
  AccountContractBuilder,
  CreateAccountScheduleEntity,
  SearchAccountResponseEntity,
} from '@core/domain/entities/scheduler/entities';
import {
  TAccountContract,
  TScheduleDateForm,
  TSearchAccountContract,
} from '@core/domain/entities/scheduler/types';
import { SchedulerService } from '@core/shared/services/scheduler';
import { TFacadeResponse } from '@core/shared/types/general.type';
import { TConfirmOTP, TGenerateOTPCode } from '@core/shared/types';
import { ScheduleStore } from '@core/stores';

@Injectable({
  providedIn: 'root',
})
export class SchedulerFacade {
  private readonly schedulerService = inject(SchedulerService);
  private readonly store = inject(ScheduleStore);

  readonly isFetching = computed(() => {
    return this.store.isFetching();
  });

  toggleFetching(isLoading: boolean) {
    this.store.doToggleFetching(isLoading);
  }

  makeAccountContractRequest(account: Partial<TSearchAccountContract>) {
    const formData = AccountContractBuilder.fromJson(account);
    this.store.doSetAccountContract(formData);

    return this.getAccountContractSearch(formData).pipe(
      tap(({ success, data }) => {
        if (success) {
          const { scheduleActive, accountInfo, availableCapabilities } = data;

          this.store.doSetAccountInfo(accountInfo);
          this.store.doSetScheduleActives(scheduleActive);
          this.store.doSetAvailableCapabilities(availableCapabilities);
        }
      }),
    );
  }

  getAccountContractSearch(
    contract: TAccountContract,
  ): Observable<IResponseMap<SearchAccountResponseEntity>> {
    return this.schedulerService.getAccountContractSchedules(contract).pipe(
      map((resp) => {
        const data = SearchAccountResponseEntity.fromJson(
          resp.validateAccountContractData,
        );

        return {
          success: true,
          data,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          message: err?.message,
          data: null,
        });
      }),
    );
  }

  generateOTPCodeByPhone(
    phone: string,
  ): Observable<TFacadeResponse<TGenerateOTPCode>> {
    return this.schedulerService.generateOTPCode(phone).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          message: err?.message,
          data: null,
        });
      }),
    );
  }

  validateOTPCode(
    otp: string,
    phone: string,
  ): Observable<TFacadeResponse<TConfirmOTP>> {
    return this.schedulerService.confirmOTPCode(otp, phone).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          message: err?.message as string,
          data: null,
        });
      }),
    );
  }

  createScheduleByAccount(
    formData: TScheduleDateForm,
  ): Observable<IResponseMap<CreateAccountScheduleEntity>> {
    return this.schedulerService.createSchedule(formData).pipe(
      map((resp) => {
        return {
          success: true,
          data: CreateAccountScheduleEntity.fromJson(resp),
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          message: err?.message as string,
          data: null,
        });
      }),
    );
  }

  cancelSchedule(scheduleId: string): Observable<IResponseMap<string>> {
    return this.schedulerService.cancelSchedule(scheduleId).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          message: err?.message as string,
          data: null,
        });
      }),
    );
  }
}
